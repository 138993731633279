import React, { useEffect } from 'react';

const GoogleAd = (props) => {
  const { slot, googleAdId } = props;

  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({})
  }, [])

  return (
    <div className="adsense">
        <ins className='adsbygoogle'
            style={{display:'block'}}
            data-ad-client={googleAdId}
            data-ad-slot={slot}
            data-ad-format='auto'
            data-full-width-responsive='true'></ins>
    </div>
  );
};

export default GoogleAd;
