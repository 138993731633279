import './css/select.css';
import React from 'react';

const CmpSelect = props =>{
    const { label, value, onChange, peajes } = props;

    const listPeajes = peajes.map((peaje) =>
        <option key={peaje} value={peaje}>{peaje}</option>
    );

    return(
        <div className='CmpSelect'>
            <label>{label}</label>
            <select value={value} onChange={onChange}>
                <option value="Peaje" disabled hidden>Peaje</option>
                {listPeajes}
            </select>
        </div>
    )
}

export default CmpSelect;