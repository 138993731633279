import "./css/filter.css";
import CmpCheckBox from "./checkbox";
import React from 'react';

const CmpFilter = (props) => {
  const {
    setCompaniaSeleccionada,
    setMercado,
    setIndexada,
    setPermanencia,
    precios,
    companiaSeleccionada,
    mercado,
    indexada,
    permanencia,
  } = props;

  const mercadoLibre = "Mercado Libre";
  const mercadoRegulado = "Mercado Regulado";
  const isIndexada = 'SiIndexada'
  const notIndexada = 'NoIndexada'
  const isPermanencia = 'SiPermanencia'
  const notPermanencia = 'NoPermanencia'

  const companies = precios
    .map((precio) => precio.nombreCompania)
    .filter((value, index, self) => self.indexOf(value) === index);

  return (
    <div className="CmpFilter">
      <div className="CmpFilterFiltros">
        <img src="/filter.png" />
        <p>Filtros</p>
      </div>

      <div className="CmpFilterCheckBox">
        <h3>Tipo de mercado</h3>
        <CmpCheckBox
          name={mercadoLibre}
          id={mercadoLibre}
          setEvent={setMercado}
          checked={mercado.includes(mercadoLibre)}
        />
        <CmpCheckBox
          name={mercadoRegulado}
          id={mercadoRegulado}
          setEvent={setMercado}
          checked={mercado.includes(mercadoRegulado)}
        />
      </div>

      <div className="CmpFilterCheckBox">
        <h3>Tarifa Indexada</h3>
        <CmpCheckBox
          name='Si'
          id={isIndexada}
          setEvent={setIndexada}
          checked={indexada.includes(isIndexada)}
        />
        <CmpCheckBox
          name='No'
          id={notIndexada}
          setEvent={setIndexada}
          checked={mercado.includes(notIndexada)}
        />
      </div>

      <div className="CmpFilterCheckBox">
        <h3>Permanencia</h3>
        <CmpCheckBox
          name='Si'
          id={isPermanencia}
          setEvent={setPermanencia}
          checked={permanencia.includes(isPermanencia)}
        />
        <CmpCheckBox
          name='No'
          id={notPermanencia}
          setEvent={setPermanencia}
          checked={permanencia.includes(notPermanencia)}
        />
      </div>

      <div className="CmpFilterSelect">
        <h3>Compañía</h3>
        <select
          value={companiaSeleccionada[0]}
          id="inputCompania"
          onChange={(event) => setCompaniaSeleccionada(event.target.value)}
        >
          <option value=""></option>
          {companies &&
            companies.map((compania) => {
              return (
                <option key={compania} value={compania}>
                  {compania}
                </option>
              );
            })}
        </select>
      </div>
    </div>
  );
};

export default CmpFilter;
