import React, { PureComponent } from 'react';
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import graficaIndexada from "./preciosIndexados.json"
import './css/graficaIndexada.css'

const CmpGraficaIndexada = () => {
    const data = graficaIndexada

    return(
        <div className='CmpGraficaIndexada'>
            <h2>Evolución de precios</h2>
            <ResponsiveContainer width="80%" height={300}>
                <ComposedChart
                width={730}
                height={250}
                data={data}
                margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                }}
                >
                <CartesianGrid strokeDasharray="2 2" />
                <XAxis dataKey="dia" />
                <YAxis yAxisId="left" />
                <YAxis yAxisId="right" orientation="right" />
                <Tooltip />
                <Legend />
                <Bar yAxisId="right" name='Volumen MWh' type="monotone" dataKey="volumen" stroke="#82ca9d" fill="#82ca9d" />
                <Line yAxisId="left" name='Precio €/MWh' type="monotone" dataKey="precio" stroke="#DB069B" fill="#DB069B" dot={false} />
                
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    )

}

export default CmpGraficaIndexada