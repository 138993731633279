import "./css/video.css"
import React from 'react';

const CmpVideo = props =>{
    //const { onClick, text, type } = props;

    return(
        <div className="CmpVideo">
            <h2>¿No sabes como rellenar el formulario?<br/><b>Te lo explicamos</b></h2>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/U9rRwxd-GPA"
            title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; 
            clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
            </iframe>
        </div>
    )
}

export default CmpVideo;