import React from 'react'
import './App.css';
import CmpForm from './components/form';
import CmpGraficaIndexada from './components/graficaIndexada';
import CmpMenu from './components/menu';
import CmpQandA from './components/qanda';
import CmpVideo from './components/video';

function App(props) {
  const {setPrecios, setTotal} = props;
  
  return (
    <div className="App HomePage">
      <CmpMenu />
      <section className="App-content">
        <CmpForm setPrecios={setPrecios} setTotal={setTotal}/>
      </section>
      <div style={{"position": "relative"}}>
        <CmpVideo />
        <div className='backgroundEspecial' />
      </div>
      <CmpQandA />
      <CmpGraficaIndexada />
    </div>
  );
}

export default App;