import React from 'react';
import Modal from "react-bootstrap/modal"
import Button from "react-bootstrap/Button"
import "./css/detalleTarifa.css"

import "../views/css/resultados.css"

function CmpDetalleTarifa(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Detalles de {props.compania} - {props.nombretarifa}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className='CmpDetalleTarifa'>
            <tbody>
              <tr>
                <th>Consumo kWh/año</th>
                <th>Precio Variable (€/kWh)</th>
                <th>Precio Fijo (€/día)</th>
              </tr>
              <tr>
                <td>≤ 5.000 (RL1)</td>
                <td>{props.varrl1}</td>
                <td>{props.fijorl1}</td>
              </tr>
              <tr>
                <td>Entre 5.000 y 15.000 (RL2)</td>
                <td>{props.varrl2}</td>
                <td>{props.fijorl2}</td>
              </tr>
              <tr>
                <td>Entre 15.000 y 50.000 (RL3)</td>
                <td>{props.varrl3}</td>
                <td>{props.fijorl3}</td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
    );
    }

    export default CmpDetalleTarifa