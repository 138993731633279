import CmpButton from "./button";
import CmpDetalleTarifa from "./detalleTarifa"
import React from "react";
import "./css/tarifa.css"
import GoogleAd from "./adsense";

const CmpTarifa = props => {
  const { precio, imagen, fijo, energia, total, destacar, slot, googleAdId } = props;
  const [modalShow, setModalShow] = React.useState(false);
  let classNameTarifa = `CmpTarifa ${destacar}`

  if (!googleAdId){
    let url = precio.url
    let telefono = `tel:${precio.telefono}`
    return(
      <>
      <div className={classNameTarifa}> 
        <div className="CmpTarifa-columna">
          <img src={imagen} className="CmpTarifa-Image"></img>
          <div className="CmpTarifa-Compania">{precio.nombreCompania}</div>
        </div>
        <div className="CmpTarifa-columna">
          <div className="CmpTarifa-title">{fijo}</div>
          <div className="CmpTarifa-precio">{precio.terminoFijo} €</div>
        </div>
        <div className="CmpTarifa-columna">
          <div className="CmpTarifa-title">{energia}</div>
          <div className="CmpTarifa-precio">{precio.energia} €</div>
        </div>
        <div className="CmpTarifa-columna">
          <div className="CmpTarifa-title">{total}</div>
          <div className="CmpTarifa-total">{precio.total} €</div>
        </div>
        <div className="CmpTarifa-butons">
          <CmpButton onClick={()=> window.open(url, "_blank")} text="Contratar Online" />
          <div>
            <CmpButton onClick={()=> window.open(telefono)} text="Llamar" clase="CmpButton RellenarAuto"/>
            <div className="CmpButton RellenarAuto" onClick={() => setModalShow(true)}>
              Ver Detalles
            </div>
          </div>
          
        </div>
      </div>
      <CmpDetalleTarifa
      show={modalShow}
      onHide={() => setModalShow(false)}
      nombretarifa={precio.nombreTarifa}
      compania={precio.nombreCompania}
      varrl1={precio.varRL1}
      varrl2={precio.varRL2}
      varrl3={precio.varRL3}
      fijorl1={precio.fijoRL1}
      fijorl2={precio.fijoRL2}
      fijorl3={precio.fijoRL3}
    />
    </>
    )
  }else{
    return(
      <>
      <div className={classNameTarifa}>
        <GoogleAd slot={slot} googleAdId={googleAdId}/>
      </div>
      </>
    )
  }
}

export default CmpTarifa;