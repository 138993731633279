import "./css/qanda.css";
import React from 'react';
import Accordion from 'react-bootstrap/Accordion';

const CmpQandA = (props) => {
  const {} = props;

  return (
    <div className="CmpQandA">
        <h2>Preguntas frecuentes</h2>
        <Accordion>
            <Accordion.Item eventKey="0">
                <Accordion.Header as="h3">¿Cómo puedo cambiar el titular de un contrato?</Accordion.Header>
                <Accordion.Body>
                    Los contratos se pueden cambiar de 2 formas:
                    <ul>
                        <li>
                            Subrogación: Se cambia el titular pero el contrato sigue con las mismas condiciones.
                        </li>
                        <li>
                            Cambio de compañía con cambio de titular: Es muy importante que si se decide esta opción, informemos a la nueva compañía
                            de que es un traspaso con cambio de titularidad. De no ser así nos rechazarían el cambio de tarifa.
                        </li>
                    </ul>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header as="h3">¿Cómo se si tengo permanencia en mi compañía?</Accordion.Header>
                <Accordion.Body>
                    <p>Para saber esto deberíamos de consultar el contrato que tenemos con la compañía, allí tenemos toda la información.</p>
                    <p><b>Seguro que te preguntas:</b> ¿Y qué pasa si no tengo este contrato porque lo he perdido? En estos casos la solución
                    es muy fácil.  🙂</p>
                    <p>Puedes llamar o escribir a tu compañía actual y pedir que te envíen el contrato. están obligados a dártelo. 😉</p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header as="h3">¿Tengo que avisar a mi anterior compañía cuando hago un cambio?</Accordion.Header>
                <Accordion.Body>
                    <p>Respuesta rápida: <b>No debes de avisar a tu anterior compañía. 👌</b></p>
                    <p>Ahora bien, ¿Por qué motivo no tengo que avisar?</p>
                    <p>En un contrato de gas hay 2 entidades, la comercializadora y la distribuidora. La comercializadora es la que conocemos todos
                        como podría ser Iberdrola, Endesa, Naturgy, etc.
                        La distribuidora es la que "hace todo el trabajo". Esta se encara de suministrar el gas hasta tu casa, poner la instalación,
                        instalar el contador, etc. 
                    </p>
                    <p>Pues bien, esta distribuidora es la que tiene un contador asignado a un cliente y por otro lado una comercializadora.
                        Esto lo que hace es que no puede tener más de una comercializadora a la vez, lo que hace que no tengas que avisar a tu
                        anterior comercializadora, ya que la nueva ocupa el lugar de la anterior.
                    </p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
                <Accordion.Header as="h3">Me ha llegado una factura muy elevada ¿Qué hago?</Accordion.Header>
                <Accordion.Body>
                    <p><b>Que no cunda el pánico</b>, a lo mejor lo podemos salvar.</p>
                    <p>Lo primero que debemos de hacer es revisar el motivo (lectura estimada, consumo muy elevado, actualización de precios...)</p>
                    <p>
                        Para no complicar y hacer un libro sobre esto, he preparado este video que te ayudara a como proceder 😉
                    </p>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    </div>
  );
};

export default CmpQandA;
