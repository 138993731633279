import React, { useState } from 'react'
import {
  Routes,
  Route,
} from "react-router-dom";
import App from "../App";
import CmpResultados from "../views/Resultados";
import NotFound from "../components/notFound";

const AppRoutes = () => {
    const [precios, setPrecios] = useState([])
    const [total, setTotal] = useState('')
    return(<Routes>
        <Route path="/" element={<App setPrecios={setPrecios} setTotal={setTotal} />} />
        <Route path="/resultados" element={<CmpResultados precios={precios} total={total} />} />
        <Route path ="*" element={<NotFound />} />
    </Routes>)
}

export default AppRoutes