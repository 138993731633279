import "./css/menu.css"
import React from 'react';

const CmpMenu = props =>{
    return(
        <div className='CmpMenu'>
            <a href="/">
                <img src="./GasLogo98.png"></img>
                <h1>El Rincón del Gas</h1>
            </a>
        </div>
    )
}

export default CmpMenu;
