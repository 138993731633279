import './css/info.css';
import React from 'react';

const CmpInfo = props => {
    const {setPrecios, setLoading} = props;

    return(
        <div className="CmpInfo">
            <p>En camino al ahorro</p>
            <h1>Compara y Ahorra<br/>Gas Natural</h1>
            <h2>Comparador de Tarifas de Gas</h2>
        </div>
    )
}

export default CmpInfo;