import "./css/input.css";
import React from 'react';

const CmpInput = (props) => {
  const { label, value, onChange, placeholder, type = "number", error, defaultValue } = props;

  return (
    <div className={`CmpInput ${error ? "CmpInputError" : ""}`}>
      <input
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        defaultValue={defaultValue}
        className="input"
        autoComplete="off"
        name="text"
      ></input>
      <label className="input-border"></label>
    </div>
  );
};

export default CmpInput;
